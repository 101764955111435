import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('dots')
    }
  }, [])

  return (
    <Layout>
      <SEO title="Uh oh!" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
